
import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import {
  getCurrentUser,
  selectIsUserLoading,
  setAccessToken,
  selectHasUser
} from 'store/auth';
import { fetchLocaleList } from 'store/localize';
import { useOnMount } from 'shared/hooks';
import { AppLayout } from 'features/AppLayout';
import { Routes } from 'features/Routes/Routes';
import {
  LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY,
  MSAL_TOKEN_KEY
} from '../../shared/constants/common.consts';
import {
  getProductCodeFromExternalPath,
  getWindow,
} from '../../shared/utils';
import { Spinner } from '../../components';

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const msalTokenKeys = sessionStorage.getItem(MSAL_TOKEN_KEY);
  const msaltokenKey = msalTokenKeys && JSON.parse(msalTokenKeys).idToken[0];
  const tokenJson = sessionStorage.getItem(msaltokenKey);
  const msalToken = tokenJson && JSON.parse(tokenJson).secret;
  const product = getProductCodeFromExternalPath(pathname);
  const isLoading = useSelector(selectIsUserLoading);
  const hasUser = useSelector(selectHasUser);

    useEffect(() => {     
      if ((msalToken && isAuthenticated ) || getWindow().APP_CONFIG.MOCK_USER) {       
        if (pathname.includes('termsofuse')) {
          localStorage.setItem(
            LOCAL_STORAGE_EXTERNAL_USER_PRODUCT_KEY,
            product
          );
        }
   
        if(!hasUser){
        dispatch(setAccessToken(msalToken));
        dispatch(getCurrentUser());
        }
      }
           // eslint-disable-next-line 
    }, [dispatch,pathname, product,msalToken,isAuthenticated]);
  

  useOnMount(
    React.useCallback((): void => {
      dispatch(fetchLocaleList());
      
    }, [dispatch])
  );

  if (isLoading) return <Spinner />;
 
  return (    
    <AppLayout>     
      <Routes />
    </AppLayout>    
  );
};