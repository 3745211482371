import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMutate } from 'restful-react';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';

import stylesButton from 'components/Button/Button.module.css';
import { selectHttpHeaders, selectSourceSubjectId } from 'store/auth';
import { API_PATH } from 'shared/constants/api.consts';
import { Sidebar, Spinner } from 'components';
import { UserFormContainer } from './UserForm/UserForm.container';
import { UserList } from './UserList/UserList';
import { UploadMultipleUser } from './MultipleUser/MultipleUser';
import styles from './AdditionalUsersSidebar.module.css';
import { useOnMount } from '../../../../../shared/hooks';
import { useOnUnmount } from '../../../../../shared/hooks/useOnUnmount';
import Close from '../../../../../assets/images/Close.svg';

type Props = {
  productRoles: Array<any>;
  submission: DTO.SubmissionRecord;
  onCloseSidebar: () => void;
};

export const AdditionalUsersSidebar = ({
  productRoles,
  submission,
  onCloseSidebar
}: Props) => {
  const httpHeaders = useSelector(selectHttpHeaders);
  const {
    canAddAdditionalUsers,
    displayId,
    organization,
    user: { firstName, lastName, email }
  } = submission;
  const { t } = useTranslation();
  const { list, add, multipleUser } = {
    list: 'LIST',
    add: 'ADD',
    multipleUser: 'MULTIPLE_USER'
  };
  const [additionalUsers, setUsers] = React.useState<any>([]);
  const [activePanel, setActivePanel] = React.useState<string>(list);
  const [showSubheading, setShowSubheading] = React.useState(false);
  const sourceSubjectId = useSelector(selectSourceSubjectId);
  const { mutate: getUsers, loading } = useMutate<any>({
    verb: 'POST',
    path: API_PATH.GET_ADDITIONAL_USERS(displayId, sourceSubjectId),
    requestOptions: () => ({ headers: httpHeaders })
  });
  const getUser = useCallback(() => {
    getUsers({ id: displayId }).then((response: any) => {
      setUsers(response);
    });
  }, [displayId, getUsers]);

  useOnMount(() => {
    getUser();
    document.body.classList.add(styles.noScroll);
  });

  useOnUnmount(() => {
    if (document.body.classList.contains(styles.noScroll)) {
      document.body.classList.remove(styles.noScroll);
    }
  });

  const changePanel = React.useCallback(
    (next: string): void => {
      getUser();
      setActivePanel(next);
      setShowSubheading(next === add);
    },
    [getUser, add]
  );

  const loadContent: () => any = () => {
    return {
      LIST: (
        <UserList
          canAddAdditionalUsers={canAddAdditionalUsers}
          changePanel={(a: any) => {
            if (a === 'X') {
              changePanel(multipleUser);
            } else {
              changePanel(add);
            }
          }}
          passData={{ email, firstName, lastName, displayId, organization }}
          reload={getUser}
          users={additionalUsers}
          onShowSubheading={(value) => setShowSubheading(value)}
        />
      ),
      ADD: (
        <UserFormContainer
          changePanel={() => changePanel(list)}
          passData={{ email, displayId, organization }}
          productRoles={productRoles}
          reload={getUser}
        />
      ),
      MULTIPLE_USER: (
        <UploadMultipleUser
          canAddAdditionalUsers={canAddAdditionalUsers}
          changePanel={() => changePanel(list)}
          isMultipleUser={true}
          passData={{ email, firstName, lastName, displayId, organization }}
          productRoles={productRoles}
          reload={getUser}
          users={additionalUsers}
          onShowSubheading={(value) => setShowSubheading(value)}
        />
      )
    };
  };

  return (
    <Sidebar>
      <Sidebar.Header>
        <React.Fragment>
          <div className={styles.header}>
            <div>
              {activePanel !== 'MULTIPLE_USER' && (
                <h6>{t('admin.adduser.submission')}</h6>
              )}
              {activePanel === 'MULTIPLE_USER' && (
                <h6>{t('admin.adduser.multipleUser')}</h6>
              )}
              {showSubheading && (
                <div className={styles.subheading}>
                  {firstName} {lastName}, {email}
                </div>
              )}
            </div>
            <button
              className={cs(
                'btn',
                stylesButton.new_btn,
                stylesButton.new_cancel_btn,
                stylesButton.new_btn_sm
              )}
              type='button'
              onClick={React.useCallback((): void => {
                if(activePanel === 'MULTIPLE_USER'){
                  changePanel(list);
                } else{
                  onCloseSidebar();
                }
              }, [onCloseSidebar, activePanel, list, changePanel])}
            >
              {activePanel === 'MULTIPLE_USER' ? <img alt='Close' src={Close} /> : t('actions.close')}
            </button>
          </div>
        </React.Fragment>
      </Sidebar.Header>
      {loading ? <Spinner shouldShow={true} /> : loadContent()[activePanel]}
    </Sidebar>
  );
};
