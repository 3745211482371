import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getFromLocalStorage, setToLocalStorage } from 'shared/utils/storage.utils';
import { UPDATE_SUBSCRIPTIONS_ACTIONS } from 'features/UpdateSubscription/UpdateSubscription.helpers';
import { Form, Spinner, Select, Dialog } from 'components';
import { selectProductCode } from 'store/product';
import { SUBMISSION_PLAN_STATUS } from 'shared/constants/submission.consts';
import {
  reselectSubscriptionPlans,
  reselectAction,
  setPlans,
  setUserAction,
  renewSubscriptions
} from 'store/updateSubscription';
// import { OptionAdminAction } from './OptionAdminAction/OptionAdminAction';
import { StepActionsPanel } from '../../../RegistrationPage/components/StepActionsPanel/StepActionsPanel';
import styles from './SelectPlans.module.css';
import { PlanCard } from './PlanCard/PlanCard';
import { selectProductPlans } from '../../../../store/product/product.selectors';

type Props = AP.DefaultRegistrationStepProps;

export const SelectPlans: React.FC<Props> = ({isNextButtonDisabled, onNext }) => {
  const productCode = useSelector(selectProductCode);
  const plans: any = useSelector(reselectSubscriptionPlans);
  const productPlans = useSelector(selectProductPlans);
  const action = useSelector(reselectAction);
  const { t } = useTranslation();  
  const [isNextDisabled, setDisabled] = React.useState<boolean>(isNextButtonDisabled && !action);
  const dispatch = useDispatch();
  const arePlansLoading = false; //useSelector(selectProductPlansLoading);
  const duration = plans && plans[0] && plans[0].duration;
  const plandurations = productPlans && productPlans[0] && productPlans[0].durations;
  const durationList = plandurations && plandurations.length > 1
    && plandurations.map((item: any, i: any) => {
      return (
        <option key={item} selected={item === duration} value={item}>{item / 12} {' Year'}</option>
      );
    }, this);

    const actionList = [
      plans.some(
        (plan: any) =>
          (plan?.status &&
            plan?.status === SUBMISSION_PLAN_STATUS.UNSUBSCRIBED) ||
          (plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.REJECTED)
      ) && {
        actionName: t(
          `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.ADD_NEW_PLAN.toLowerCase().replaceAll(
            ' ',
            ''
          )}`
        ),
        icon: 'icon-plus-special align-text-bottom h5 ml-1 mr-1',
        className: '',
        id: '4'
      },
      plans.some(
        (plan: any) =>
          (plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.ACTIVE) ||
          (plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.EXPIRED) ||
          (plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.INACTIVE)
      ) && {
        actionName: t(
          `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.RENEW.toLowerCase()}`
        ),
        icon: 'align-text-bottom h5 icon-stopwatch-special ml-1 mr-1',
        className: '',
        id: '3'
      },
      plans.some(
        (plan: any) =>
          plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.CANCELLED
      ) && {
        actionName: t(
          `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.RE_ACTIVATE.toLowerCase().replaceAll(
            '-',
            ''
          )}`
        ),
        icon: `icon-backup-special align-bottom h4 ${styles.reactivate_icon}`,
        className: '',
        id: '1'
      },
      plans.some(
        (plan: any) =>
          (plan?.status && plan?.status === SUBMISSION_PLAN_STATUS.ACTIVE)
      ) && {
        actionName: t(
          `registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.CANCEL.toLowerCase()}`
        ),
        icon: 'align-text-bottom h5 icon-no-entry-special ml-1 mr-1',
        className: styles.cancel_option,
        id: '2'
      }
    ];

    const ActionItemNames = actionList.filter(x=>x.actionName).map(x=> `'${x.actionName}'`);
    
    let strActionNames = '';
    if(ActionItemNames.length > 1)
    {
      const lastOne = ActionItemNames.pop();
      strActionNames= ActionItemNames.join(', ').concat(' or ', lastOne);
    }
    else
      strActionNames= ActionItemNames.join(', ');

    // const ActionItems = actionList.map((plan: any) => ({
    //   ...plan,
    //   checked: false
    // }));
    
  const [licenseValidation, setlicenseValidation] = React.useState<boolean>(false);
  const [mipDuration, setmipDuration] = React.useState<number>(duration);
  const [isALZeroPLNot, setALFlag] = React.useState<boolean>(false);
   const [showDialog, setShowDialogFlag] = React.useState<boolean>(false);
  useEffect(() => {
    setmipDuration(duration);
  }, [duration]);

  const handleOnClose = React.useCallback((): void => {
    setlicenseValidation(false);
    setALFlag(false);
    setShowDialogFlag(false);
  }, [setlicenseValidation, setALFlag]);

  const handleDialogWhenALZeroOnClose = React.useCallback((): void => {
    setALFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setALFlag]);

  const proceedWithNextClickAction = () => {
    const plansFetch = plans.length > 0 ? plans :  getFromLocalStorage('plans');
    if(plansFetch.length > 0) {
      const actionValue = action || getFromLocalStorage('actionValue');
      if (actionValue === t(`registration.plans.actions.${UPDATE_SUBSCRIPTIONS_ACTIONS.RENEW.toLowerCase()}`)) {
        const renewedPlans = plansFetch.map((plan: any) => {
          if (plan.checked && plan.status !== 'PendingRenewal') {
            return { ...plan, duration: mipDuration, status: 'PendingRenewal' };
          }
          return plan;
        });
        dispatch(renewSubscriptions(renewedPlans));
      }
      // before next click saving current plans in cache.
      const activePlans = plansFetch.find((op: any) => {
        return op.status.toLowerCase() === SUBMISSION_PLAN_STATUS.ACTIVE.toLowerCase();
      });
      const isActiveplanPresent = !(activePlans === undefined || activePlans === null);
      setToLocalStorage('showCancelButton', isActiveplanPresent);
      setToLocalStorage('initialPlans', plansFetch);
      onNext();
    }
    setALFlag(false);
  };
  
  const handleOkClick = React.useCallback((): void => {
    proceedWithNextClickAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const onNextStep = () => {
    const isPurchasedLicenseZero = plans.some((plan: any) => 
      plan.checked && plan.title !== t('admin.plans.names.TSRMonitoring') && plan.purchasedLicenses === 0);
    const isAvailableLicensesZero = plans.some((plan: any) => 
    plan.checked && plan.title !== t('admin.plans.names.TSRMonitoring')
    && plan.status !== SUBMISSION_PLAN_STATUS.REJECTED 
    && plan.status !== SUBMISSION_PLAN_STATUS.CANCELLED 
    && plan.status !== SUBMISSION_PLAN_STATUS.EXPIRED && plan.availableLicenses === 0);
    if(productCode === 'sbp' && isPurchasedLicenseZero) {
      setlicenseValidation(true);
    } else if(productCode === 'sbp' && isAvailableLicensesZero && !isPurchasedLicenseZero) {
      // handle dialog with ok & cancel button logic
      setALFlag(true);
      setToLocalStorage('plans', plans);
    } else {
      proceedWithNextClickAction();
    }
  };
  const handlePlanCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = event;
    const { name: code, checked } = currentTarget;
    let isDisabled = true;
    // eslint-disable-next-line no-restricted-globals
    const updatedPlans = plans.map((plan: any) => {
      const isChecked = plan.code === code ? checked : plan.checked;
      if (isChecked) {
        isDisabled = false;
         if(!action)
        setShowDialogFlag(true);
      }
      return {
        ...plan,
        checked: isChecked
      };
    });
    setDisabled(isDisabled);
    dispatch(setPlans(updatedPlans));
  };
  // hook required, to handle next button visibility based on checked plans
  useEffect(() => {
    const tempPlans = plans.find((plan: any) => {
      return plan.checked === true;
    });
    setDisabled(!tempPlans || tempPlans?.length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  const checkVisibility = () => {
    const checkedPlans = plans.find((plan: any) => {
      return plan.checked === true;
    });
    return isNextDisabled  || (!checkedPlans || checkedPlans?.length === 0) || !action;
  };
  const generateLicenseValidationMessage = useCallback(
    (): any => {
      let vaildationMsg = '';
      plans.forEach((plan: any) => {
        if (plan.checked && plan.purchasedLicenses === 0) {
          // vaildationMsg = plan.status === SUBMISSION_PLAN_STATUS.UNSUBSCRIBED ?
          //   t('dialogs.purchasedLicenseZero') : t('dialogs.requestedLicenseZero');
           vaildationMsg = plan.status === SUBMISSION_PLAN_STATUS.REJECTED ?
           t('dialogs.requestedLicenseZero') : t('dialogs.purchasedLicenseZero');
        } else if (plan.checked && plan.availableLicenses === 0 && plan.purchasedLicenses !== 0 
          && (plan.status !== SUBMISSION_PLAN_STATUS.REJECTED 
              && plan.status !== SUBMISSION_PLAN_STATUS.CANCELLED)) {
          vaildationMsg = t('dialogs.availableLicenseZero');
        }
      });
      return vaildationMsg;
    },
    [plans, t]
  );

  return arePlansLoading ? (
    <Spinner shouldShow={true} />
  ) : (
    <React.Fragment>
      {/* Hiding the below control from here */}
      {/* <div className={styles.actionSelect}>
        <div className={styles.dropdownaction}>
          <Select<any>
            className={styles.action}
            getOptionValue={getOptionValue}
            isClearable={false}
            isSearchable={false}
            OptionComponent={OptionAdminAction}
            options={actionList.filter((actionItem: any) => actionItem !== false)}
            placeholderPrefix={placeholder}
            renderOption={renderSelectActions}
            value=''
            onChange={selectAction}
          />
        </div>
      </div> */}
      <Form className={styles.container}>
        {plans.map(
          ({
            availableLicenses,
            code,
            checked,
            title,
            model,
            purchasedLicenses,
            status,
            performedAt,
            expiredAt,
            activationDate
          }: any) => {
            const planDescription = productPlans.find(
              (plan: any) => plan.code === code
            )?.description;
            return (
              <PlanCard
                key={title}
                action={action}
                activationDate={activationDate}
                availableLicenses={availableLicenses}
                checked={checked}
                code={code}
                description={planDescription}
                durationList={durationList}
                expiredAt={expiredAt}
                handlePlanCheck={handlePlanCheck}
                model={model}
                performedAt={performedAt}
                productCode={productCode}
                purchasedLicenses={purchasedLicenses}
                setmipDuration={setmipDuration}
                status={status}
                title={title}
              />
            );
          }
        )}
      </Form>
      <StepActionsPanel
        className={styles.stepActionPanel}
        directCancel={true}
        isNextDisabled={checkVisibility()}
        onNext={onNextStep}
      />
        <Dialog
          actions={ isALZeroPLNot ? [
            {
              label: t('actions.cancel'),
              variant: 'outline-secondary',
              onClick: handleDialogWhenALZeroOnClose
            },
            {
              label: t('actions.ok'),
              variant: 'primary',
              onClick: handleOkClick
            }
          ]
        : [{
            label: t('actions.ok'),
            // variant: 'primary',
            onClick: handleOnClose
          }
        ]}
          className='noBorderAndPadding'
          show={licenseValidation || isALZeroPLNot}
          title={t('dialogs.addLicense')}
          onClose={handleOnClose}
          onClosed={() => {}}
          onHide={handleOnClose}
        >
          <p className={styles.warningText}>
            {generateLicenseValidationMessage()}
          </p>
        </Dialog>
        <Dialog        
        className='noBorderAndPadding'
        show={showDialog}
        title={t('dialogs.updateManageSubscription.title')}
        onClose={handleOnClose}
        onClosed={() => {}}
        onHide={handleOnClose}
      >
        <div className={styles.warningText}>
          {t('dialogs.updateManageSubscription.message')+ strActionNames} 
        </div>
      </Dialog>
    </React.Fragment>
  );
};
