import {PublicClientApplication} from '@azure/msal-browser';

import { getWindow } from './shared/utils';

const config = {
  auth: {
    authority: `${getWindow().APP_CONFIG.AUTHORITY_URL}/${
      getWindow().APP_CONFIG.AAD_TENANT_ID
    }`,
    clientId: `${getWindow().APP_CONFIG.AAD_CLIENT_ID}`,
    redirectUri:  `${getWindow().APP_CONFIG.GAP_APP_URL}/home`,
    postLogoutRedirectUri:  `${getWindow().APP_CONFIG.GAP_APP_URL}/home`
  },
  cache: {
    localStorage: true,
    storeAuthStateInCookie: true
  }
};
// Authentication Parameters

export const loginRequest = {
  scopes: ['user.read']
};

/* // Options//*/
export const options = {  
  tokenRefreshUri: getWindow().APP_CONFIG.GAP_APP_URL
}; 
export const pca = new PublicClientApplication(config);