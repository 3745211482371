import React, {
  useState,
  useRef,
  FC,
  useReducer,
  useCallback,
  useEffect,
  useMemo,
  Fragment
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutate } from 'restful-react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { changeLanguage, reselectLocaleCode } from 'store/localize';
import { exportCSVFile, fetchData } from 'shared/utils';
import {
  reselectAction
} from 'store/updateSubscription';
import { selectHttpHeaders, reselectUser, setCurrentUserFilters, selectSourceSubjectId, setSourceSubjectId } from 'store/auth';
import { useOnMount } from 'shared/hooks';
import { selectProductCode } from 'store/product';
import { Pagination, Spinner, Badge, Icon } from 'components';
import { SUBMISSION_TYPE } from 'shared/constants/submission.consts';
import { API_PATH } from 'shared/constants/api.consts';
import { errorException } from 'shared/utils/AppInsights';
import { NoGridData } from '../NoGridData/NoGridData';
import { AdminHeader } from '../AdminPageHeader/AdminPageHeader';
import { SubmissionsGridContainer } from '../SubmissionsGrid/SubmissionsGrid.container';
import { SubmissionsTabs } from '../SubmissionsTabs/SubmissionsTabs';
import { Sorting } from '../Sorting/Sorting';
import {
  AdminContext,
  GRID_OPTIONS_ACTION_TYPES,
  gridOptionsReducer,
  initialGridOptions,
  PLAN_STATUS
} from './AdminPageContent.helpers';
import { SubmissionsFilters } from '../SubmissionsFilters/SubmissionsFilters';
import { SubmissionsQueryResponse } from '../SubmissionsGrid/SubmissionsGrid.helpers';
import styles from './AdminPageContent.module.css';
import { APP_PATH } from '../../Routes/Routes.helpers';
import {
  selectUpdatedPrimaryId,
  setUpdatedPrimaryUser
} from '../../../store/global';
import circleCrossOmnia from '../../../assets/images/circleCrossOmnia.svg';
import { USER_ROLES } from '../OnboardingPage/OnboardingFilters/OnboardingFilters';
import { FilterBadge } from '../SubmissionsFilters/FilterBadge/FilterBadge';

export const AdminPageContent: FC = () => {
  const productCode = useSelector(selectProductCode);
  const httpHeaders = useSelector(selectHttpHeaders);
  const localeCode = useSelector(reselectLocaleCode);
  const sourceSubjectId = useSelector(selectSourceSubjectId);
  const user = useSelector(reselectUser);
  const updatedPrimaryId = useSelector(selectUpdatedPrimaryId);
  const filtersContainer = useRef();
  const [totalItems, setTotalItems] = useState(0);
  const { t } = useTranslation();
  const [gridOptions, dispatchGridOptions] = useReducer(
    gridOptionsReducer,
    initialGridOptions
  );
  const [submissions, updateSubmissions] = useState<DTO.SubmissionRecord[]>([]);
  const [showUserIconFlag, setShowUserIconDisplayFlag] = useState(false);
  const [roles, updateProductRoles]= useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [registrationTabsCount, updateRegistrationTabsCount] = useState<
    DTO.RegistrationTabsCount
  >({
    pending: 0,
    processed: 0
  });
  const [isApplyButtonClicked, setApplyButtonClicked] = useState(false);
  const dispatch = useDispatch();
  const action = useSelector(reselectAction);


  const {
    mutate: getGridData,
    loading: isGridLoading,
    error: gridError
  } = useMutate({
    verb: 'POST',
    path: API_PATH.GET_SUBMISSIONS_GRID(productCode, localeCode),
    requestOptions: () => ({ headers: httpHeaders })
  });

  const { mutate: exportCSV, error: exportCSVError } = useMutate({
    verb: 'POST',
    path: API_PATH.EXPORT_REGISTRATIONS(productCode, localeCode),
    requestOptions: () => ({ headers: httpHeaders })
  });

  const handleQueryResponse = useCallback(
    ({ items, productRoles, submissionsCount, showUserIcon }: SubmissionsQueryResponse): void => {
      updateSubmissions(items);
      updateProductRoles(productRoles);
      setShowUserIconDisplayFlag(showUserIcon);
      updateRegistrationTabsCount(submissionsCount);
    },
    []
  );

  const handleGetGridData = useCallback(
    (saveFilters = false): Promise<void> =>{
    dispatch(setCurrentUserFilters(gridOptions.filters));

     return getGridData({...gridOptions.query, saveFilters}).then(data => {
        handleQueryResponse(data);
        setLoaded(true);
         setTotalItems(data.allItemsCount);
      });
    },
    // because getGridData updates the reference multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridOptions.query, handleQueryResponse, productCode, localeCode]
  );

  const exportDataCb = (): Promise<void> => {
    return exportCSV(gridOptions.query)
      .then(res => res.text())
      .then(data => {
          exportCSVFile(data, t('registration.data.title.registration'));
    });
  };
  const appliedFilters = useMemo(
    () =>
      Object.values(gridOptions.filters).reduce(
        (acc, filter) => (filter && filter.length > 0 ? acc + 1 : acc),
        0
      ),
    [gridOptions.filters]
  );

  const handleSortOnChange = useCallback(
    (sortBy: string, sortDirection: AP.SortDirection): void => {
      dispatchGridOptions({
        type: GRID_OPTIONS_ACTION_TYPES.CHANGE_SORTING,
        payload: { sortBy, sortDirection }
      });
    },
    []
  );

  const handleFiltersOnChange = useCallback(
    (updatedFilters: DTO.SubmissionFilters) => {
      dispatchGridOptions({
        type: GRID_OPTIONS_ACTION_TYPES.CHANGE_FILTERS,
        payload: updatedFilters
      });
    },
    []
  );

  const handlePageSizeOnChange = useCallback((pageSize: number): void => {
    dispatchGridOptions({
      type: GRID_OPTIONS_ACTION_TYPES.CHANGE_PAGE_SIZE,
      payload: pageSize
    });
  }, []);

  const handlePageNumberOnChange = useCallback((pageNumber: number): void => {
    dispatchGridOptions({
      type: GRID_OPTIONS_ACTION_TYPES.CHANGE_PAGE_NUMBER,
      payload: pageNumber
    });
  }, []);

  const handleSubmissionTypeChange = useCallback((type: string) => {
    dispatchGridOptions({
      type: GRID_OPTIONS_ACTION_TYPES.CHANGE_ONLY_PENDING,
      payload: SUBMISSION_TYPE.PENDING === type ? 1 : 2
    });
  }, []);

  const removeFilter = (key: any, filterToRemove: any) => {
    const tempFilter = gridOptions.filters[key as keyof typeof gridOptions.filters];
    handleFiltersOnChange({
      ...gridOptions.filters,      
      [key]: tempFilter.filter((item:any) => !Object.values(item).includes(filterToRemove))
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const appliedFiltersValue = (filterArr: any) => {
    if (filterArr.some((item: any) => item.value.length > 0)) {
      return (
        <FilterBadge 
          filterArray={filterArr}
          removeFilter={removeFilter}
        />
      );
    }
    return null;
  };

  useOnMount(
    useCallback((): void => {
      if (user.profileSettings)
        dispatch(changeLanguage(user.profileSettings.language));
    }, [dispatch, user.profileSettings])
  );

  useEffect(() => {
    if (action.toLocaleLowerCase() === 'cancel') {
      handleSubmissionTypeChange(SUBMISSION_TYPE.PROCESSED);
    }
  });

  useEffect(() => {
    if (updatedPrimaryId !== null) {
      handleGetGridData().catch((err) => {
        dispatch(setUpdatedPrimaryUser(null));
        // AppInsights Error Exception
        errorException(`Error: ${err}, File reference: AdminPageContent.tsx in useEffect function`);
      });
    }
  }, [updatedPrimaryId, handleGetGridData, dispatch, gridOptions.filters]);

  useEffect((): void => {
    if (productCode !== null) {
      // the reason is error awareness
      // eslint-disable-next-line no-console
      handleGetGridData(isApplyButtonClicked).catch((err) => {
        // AppInsights Error Exception
        errorException(`Error: ${err}, File reference: AdminPageContents.tsx in useEffect function, handleGetGridData catch`);
      });
      setApplyButtonClicked(false);
    }

    /**
     * because this callback should run
     * only when the next dependencies have changed
     */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [gridOptions.query, productCode, localeCode]);

  useEffect(() => {
    if (productCode !== null) {
      handleFiltersOnChange({
        'countryCode': [],
        'legalName': [],
        'user': [],
        'planStatus': [],
        'subscriberEmail': []
      });
    }
  }, [productCode, handleFiltersOnChange]);

  useEffect(() => {
    async function setData() {
      if(sourceSubjectId === ""){
          const data: any = await fetchData(API_PATH.GET_PROFILE, httpHeaders);

          if(data !== null){
            dispatch(setSourceSubjectId(data.sourceSubjectId));
          }
      }
    }
    setData();
  }, [sourceSubjectId, httpHeaders, dispatch]);

  if (
    (gridError && gridError.status === 403) ||
    (exportCSVError && exportCSVError.status === 403)
  ) {

    // AppInsights Error Exception
    if(gridError.status === 403){
      errorException('Error bad request to .../product/<product>/registrations/query/locale/<localeCode>, File reference: AdminPageContent.tsx in useEffect function, gridError');
    }
    if(exportCSVError.status === 403){
      errorException('Error bad request to .../product/<product>/registrations/export/locale/<locale>, File reference: AdminPageContent.tsx in useEffect function, exportCSVError');
    }

    return <Redirect to={APP_PATH.ACCESS_DENIED} />;
  }

  const submissionType =
    gridOptions.query.filter.planStatus[0] === PLAN_STATUS.PENDING
      ? SUBMISSION_TYPE.PENDING
      : SUBMISSION_TYPE.PROCESSED;

  if (isGridLoading || !isLoaded) return <Spinner />;

  return (
    <AdminContext.Provider
      value={{
        type: submissionType,
        onSubscriptionsSubmit: handleGetGridData
      }}
    >
      <AdminHeader
        disabledExport={
          !submissions.length || registrationTabsCount[submissionType] === 0
        }
        onExport={exportDataCb}
      />
      <div className={styles.filterPanel}>
        <SubmissionsTabs
          data={registrationTabsCount}
          handleFiltersOnChange={handleFiltersOnChange}
          setSubmissionType={handleSubmissionTypeChange}
          submissionType={submissionType}
        />
        <div className={styles.sortAndFilters}>
          <SubmissionsFilters
            appendTo={filtersContainer}
            appliedFilters={appliedFilters}
            countryOptionValue='code'
            filters={gridOptions.filters}
            showFilters={user.role === USER_ROLES.SUPER_ADMIN ? ['Country'] : ['Country', 'Company Name', 'Subscriber Name', 'Subscriber Email']}
            onApply={() => setApplyButtonClicked(true)}
            onChange={handleFiltersOnChange}
          />
        </div>
      </div>
      <div ref={filtersContainer} />
      {gridOptions?.selectedFilters ? (
        appliedFiltersValue(gridOptions.selectedFilters)
      ) :
        null}
      {submissions.length > 0 ? (
        <Fragment>
          <SubmissionsGridContainer
            className={styles.grid}
            direction={gridOptions.query.sorter.fields[0].sortDirection} // for new we have only one sorting option, so no need for select
            gridHeaders={gridOptions.submissionHeaders}
            handleGetGridData={handleGetGridData}
            loading={isGridLoading}
            productRoles={roles}
            showUserIcon={showUserIconFlag}
            sortBy={gridOptions.query.sorter.fields[0].sortBy}
            submissions={submissions}
            userRole={user?.role}
            onSortChange={handleSortOnChange}
          />
          {totalItems > 0 && (
            <Pagination
              className={styles.pagination}
              currentPage={gridOptions.query.pageNumber + 1}
              pageSize={gridOptions.query.pageSize}
              reversedMenuList={true}
              total={totalItems}
              withPageNumberInput={true}
              withPageSizeSelector={true}
              onPageChange={handlePageNumberOnChange}
              onPageSizeChange={handlePageSizeOnChange}
            />
          )}
        </Fragment>
      ) : (
        <NoGridData areFilterEnabled={appliedFilters} />
      )}
    </AdminContext.Provider>
  );
};
