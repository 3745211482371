import React, { useRef, useEffect, useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { useMsal } from '@azure/msal-react';

import { Icon } from 'components';
import styles from './AutoLogout.module.css';

export const AutoLogout = (): JSX.Element => {
  const [signoutTime] = useState(120000);
  const [warningTime] = useState(1680000); //28 min
  const [showModal, setModal] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const warnTimeout = useRef(null);
  const logoutTimeout = useRef(null);
  const setIntervalTime = useRef(null);
  const isModalShow = useRef(false);
  const { t } = useTranslation();
  const totalSeconds = 120;
const {instance} = useMsal();
  const warn = () => {
    setModal(true);
    isModalShow.current = true;
  };

  const logout = useCallback(() =>  {
    if (isModalShow.current) {
      localStorage.setItem('logoutRedirect', "1");    
      instance.logoutRedirect({
        postLogoutRedirectUri: instance.getConfiguration().auth.postLogoutRedirectUri
      }); 
      localStorage.setItem('logout', `logout${Math.random()}`);
    }
  },[instance]);

  const upadateTime = () => {
    setSeconds(sec => sec - 1);
  };

  const setTimeouts = useCallback(() => {
    warnTimeout.current = setTimeout(warn, warningTime);
    logoutTimeout.current = setTimeout(logout, signoutTime);
  }, [warningTime, signoutTime,logout]);

  const setWarnTimeouts = useCallback(() => {
    warnTimeout.current = setTimeout(warn, warningTime);
    logoutTimeout.current = setTimeout(logout, signoutTime);
  }, [warningTime, signoutTime,logout]);

  function clearTimeouts() {
    clearTimeout(warnTimeout.current);
    clearTimeout(logoutTimeout.current);
  }

  function resetAll() {
    clearInterval(setIntervalTime.current);
    clearTimeouts();
    setTimeouts();
  }

  const handleOnClose = () => {
    resetAll();
    setModal(false);
    isModalShow.current = false;
    localStorage.setItem('session-extent', `sessionExtent${Math.random()}`);
  };

  useEffect(() => {
    const handleMultiTab = (event: any) => {
      if (event.key === 'logout') {
        localStorage.clear();
        window.location.href = '/home';
      }
      if (event.key === 'session-extent') {
        resetAll();
        setModal(false);
        isModalShow.current = false;
        localStorage.removeItem('session-extent');
      }
      if (event.key === 'events') {
        if (!isModalShow.current) {
          clearTimeouts();
          setWarnTimeouts();
          localStorage.removeItem('events');
        }
      }
    };
    window.addEventListener('storage', handleMultiTab);
    return function cleanup() {
      window.removeEventListener('storage', handleMultiTab);
    };
  });

  useEffect(() => {
    if (showModal) {
      setSeconds(totalSeconds);
    }
  }, [showModal]);

  useEffect(() => {
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    const resetTimeout = () => {
      if (showModal) {
        clearTimeouts();
        setTimeouts();
      }
    };

    if (showModal) setIntervalTime.current = setInterval(upadateTime, 1000);

    const resetWarnTimeout = () => {
      if (!isModalShow.current) {
        clearTimeouts();
        setWarnTimeouts();
        localStorage.setItem('events', `events${Math.random()}`);
      }
    };

    events.forEach((value: string) => {
      window.addEventListener(value, resetWarnTimeout);
    });

    setTimeouts();
    return () => {
      events.forEach((value: string) => {
        window.removeEventListener(value, resetTimeout);
        clearTimeouts();
      });
    };
  }, [setTimeouts, setWarnTimeouts, showModal]);

  return (
    <div>
      <Modal
        backdrop='static'
        centered={true}
        show={showModal}
        onHide={handleOnClose}
      >
        <Modal.Header className={styles.header}>
          <Modal.Title className={styles.title}>
            {t('actions.logOutWarning')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.body}>
          <div className={styles.iconCircle}>
            <Icon
              className={styles.icon}
              name='exclamation-mark-special'
              size={52}
            />
          </div>
          <div className={styles.text}>
            <p className={styles.message}>
              <h3>
                {t('actions.logOutWarningMsg')} {seconds} seconds.
              </h3>
            </p>
            <p className={styles.note}>{t('actions.logOutWarningNote')}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <Button
            className={styles.yesaction}
            size='sm'
            variant='outline-secondary'
            onClick={logout}
          >
            {t('forms.yes')}
          </Button>
          <Button
            className={styles.action}
            size='sm'
            variant='primary'
            onClick={handleOnClose}
          >
            {t('actions.No')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
