import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation, } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { setProductFromPath } from 'store/product'; 
import {
  AdminRoutes,
  TermsOfUseRoutes,
  OtherRoutes,
  RegistrationRoutes
} from './components';
import { APP_PATH, RoutesContext } from './Routes.helpers';

export const Routes = (): JSX.Element => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAuthenticated = useIsAuthenticated();

  const setProductCallback = useCallback((): void => {
    dispatch(setProductFromPath(pathname));
  }, [dispatch, pathname]);


  const adminRoutes = useCallback(() => {
    if (window.location.hostname === 'localhost') {
      return <AdminRoutes />;
    }
    if(isAuthenticated){
      return <AdminRoutes />;     
    }
    if( localStorage.getItem('logoutRedirect') === '1'){
      return <Redirect to="{APP_PATH.HOME}"/>;  
    }
    return <Redirect to={APP_PATH.ROOT}/>;       
  }, [isAuthenticated]);

  const termsOfUseRoutes = useCallback(() => {
    if (window.location.hostname === 'localhost') {
      return <TermsOfUseRoutes />;
    }
    return ( 
     <TermsOfUseRoutes />
    );
  }, []);

  return (
 
    <RoutesContext.Provider value={{ setProductCallback }}>
      <Switch>
        <Route path={APP_PATH.ADMIN_ROOT} render= {adminRoutes} />
        <Route path={APP_PATH.EXTERNAL_TERMS_OF_USE} render={termsOfUseRoutes} />
        <Route path={APP_PATH.INTERNAL_TERMS_OF_USE} render={termsOfUseRoutes} />      
        <Route
          component={RegistrationRoutes}
          path={APP_PATH.REGISTRATION_ROOT}
        />
        <Route component={OtherRoutes} path={APP_PATH.ROOT} />
      </Switch>
    </RoutesContext.Provider> 
  );
};
