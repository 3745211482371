import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cs from 'classnames';

import { reselectUser } from 'store/auth';
import { Form, Icon, Select } from 'components';
import { SearchCallback } from '../FiltersControls/FiltersControls.helpers';
import { OptionFilters } from '../OptionFilters/OptionFilters';
import styles from './FilterRow.module.css';
import circleCrossFilter from '../../../../assets/images/circleCrossFilter.svg';

interface Option {
    value: string;
    label: string;
}

interface Props {
    countries: DTO.Country[];
    onCompaniesSearch: SearchCallback<Partial<DTO.Organization>>;
    onUsersSearch: SearchCallback<Partial<DTO.User>>;
    setFieldValue: (name: string, value: string[]) => void;
    noOptionsMessage: () => string;
    loadingMessage: () => string;
    showFilters: DTO.FilterFields[];
    onAdminsSearch?: SearchCallback<DTO.UserEmail>;
    activeRole: number;
    onSubscriberEmailSearch: SearchCallback<Partial<DTO.User>>;
    addMoreFilter: Array<number>;
    key?: string;
    appliedFilter: any;
    addFilter: any;
    removeFilterRow: (p1: any, p2: any) => void;
    setAddMoreFilter: (a1: any) => void;
}

export function FilterRow({
    onSubscriberEmailSearch,
    countries,
    onCompaniesSearch,
    onUsersSearch,
    setFieldValue,
    noOptionsMessage,
    loadingMessage,
    showFilters,
    onAdminsSearch,
    activeRole,
    addMoreFilter,
    key,
    addFilter,
    appliedFilter,
    removeFilterRow,
    setAddMoreFilter
}: Props): JSX.Element {
    const { t } = useTranslation();
    const user = useSelector(reselectUser);

    const filterDataMapping = {
        'Admin Name': 'displayName',
        'Admin Email': 'emails',
        'Country': 'countryCode',
        'User Name': 'displayName',
        'Email': 'emails',
        'Company Name': 'legalName',
        'Subscriber Name': 'user',
        'Subscriber Email': 'subscriberEmail'
    };

    const [selectedFilter, setSelectedFilter] =
        React.useState<{ label: string, value: string }>
            (null ||
            {
                label: Number.isNaN(Number(addFilter)) ? addFilter : t('placeholders.selectFilter'),
                value: Number.isNaN(Number(addFilter)) ? addFilter : t('placeholders.selectFilter')
            });
    const [allSelectedFilter, setAllSelectedFilter] = React.useState<any>([]);
    const [filterOptions, setfilterOptions] = React.useState<any>([]);

    const removeFilter = (filterToRemove: any) => {
        addMoreFilter.splice(Number(addFilter) - 1, 1);
        setAddMoreFilter(addMoreFilter);
        setFieldValue(filterToRemove, []);
    };

    const handleCountryChange = React.useCallback(
        (): void => setFieldValue('countryCode', []),
        [setFieldValue]
    );

    React.useEffect((): void => {
        setfilterOptions(showFilters.map((filter) => {
            return { label: filter, value: filter };
        })
        );
    }, [showFilters, addMoreFilter]);

    const checkSelectedOption = (label: any) => {
        return Object.keys(appliedFilter).some(item =>
            filterDataMapping[label as keyof typeof filterDataMapping] === item &&
            appliedFilter[item]?.length > 0
        );
    };

    const renderOption = React.useCallback(
        ({ label }: Option): JSX.Element => (
            <div
                className={cs(
                    styles.option,
                    checkSelectedOption(label) ? styles.disableOption : styles.enableOption
                )}
            >
                <span>{label}</span>
                <Icon
                    className={styles.selectedOption}
                    name='checkbox-tick-special'
                    size={24}
                />
            </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appliedFilter]
    );

    const handleFilterChange = React.useCallback(
        (filter: Option): void => {
            setSelectedFilter(filter);
            setAllSelectedFilter([...allSelectedFilter, filter]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const additionalThemeProps = {
        borderRadius: 0,
        colors: {
            neutral0: '#FFFFFF',
            primary25: '#EBF8FD',
            primary: '#007CB0'
        },
        spacing: {
            baseUnit: 4,
            controlHeight: 4,
            menuGutter: 2
        }
    };

    return (
        <div key={key} className={cs(styles.selectContainer, styles.content, 'col-sm-12')}>
            <div className={cs(styles.filterSelect, 'col-sm-6')}>
                <Select<Option>
                    additionalThemeProps={additionalThemeProps}
                    className={styles.userFilters}
                    isClearable={false}
                    isSearchable={false}
                    OptionComponent={OptionFilters}
                    options={filterOptions}
                    placeholder={t('placeholders.selectFilter')}
                    renderOption={renderOption}
                    value={selectedFilter}
                    withDropdownIndicator={true}
                    onChange={handleFilterChange}
                />
            </div>
            <div className={cs(styles.filterSearch, 'col-sm-6')}>
                {
                    (selectedFilter?.label === 'Admin Name' || selectedFilter?.label === 'User Name')
                    && (
                        <Form.SelectAsyncFilters
                            className={styles.userControl}
                            load={onUsersSearch}
                            loadMessage={loadingMessage}
                            multiple={true}
                            name='displayName'
                            noOptions={noOptionsMessage}
                            optionLabel='fullName'
                            optionValue='fullName'
                            placeholder={t('placeholders.enterAdminName')}
                        />
                    )}
                {
                    (selectedFilter?.label === 'Admin Email' || selectedFilter?.label === 'Email')
                    && (
                        <Form.SelectAsyncFilters
                            className={styles.userControl}
                            load={onAdminsSearch}
                            loadMessage={loadingMessage}
                            multiple={true}
                            name='emails'
                            noOptions={noOptionsMessage}
                            optionLabel='email'
                            optionValue='email'
                            placeholder={t('placeholders.enterAdminEmail')}
                        />
                    )}
                {(activeRole !== 1) && (
                    <React.Fragment>
                        {
                            selectedFilter?.label === 'Country'
                            && (
                                <Form.SelectFilters
                                    className={styles.userControl}
                                    multiple={true}
                                    name='countryCode'
                                    optionLabel='name'
                                    options={countries || []}
                                    optionValue='code'
                                    placeholder={t('placeholders.all')}
                                    onChange={handleCountryChange}
                                />
                            )}
                    </React.Fragment>
                )}
                {user.role !== 'superAdmin' && (
                    <React.Fragment>
                        {
                            selectedFilter?.label === 'Company Name'
                            && (
                                <Form.SelectAsyncFilters
                                    className={styles.userControl}
                                    load={onCompaniesSearch}
                                    loadMessage={loadingMessage}
                                    multiple={true}
                                    name='legalName'
                                    noOptions={noOptionsMessage}
                                    optionLabel='legalName'
                                    optionValue='id'
                                    placeholder={t('placeholders.all')}
                                />
                            )}
                        {
                            selectedFilter?.label === 'Subscriber Name'
                            && (
                                <Form.SelectAsyncFilters
                                    className={styles.userControl}
                                    load={onUsersSearch}
                                    loadMessage={loadingMessage}
                                    multiple={true}
                                    name='user'
                                    noOptions={noOptionsMessage}
                                    optionLabel='fullName'
                                    optionValue='email'
                                    placeholder={t('placeholders.enterSubscriberName')}
                                />
                            )}
                        {
                            selectedFilter?.label === 'Subscriber Email'
                            && (
                                <Form.SelectAsyncFilters
                                    className={styles.userControl}
                                    load={onSubscriberEmailSearch}
                                    loadMessage={loadingMessage}
                                    multiple={true}
                                    name='subscriberEmail'
                                    noOptions={noOptionsMessage}
                                    optionLabel='email'
                                    optionValue='email'
                                    placeholder={t('placeholders.enterSubscriberEmail')}
                                />
                            )}
                    </React.Fragment>
                )}
            </div>
            <button className={styles.removeFilterBtn}
                onClick={() => {
                    removeFilter(filterDataMapping[selectedFilter.label as keyof typeof filterDataMapping]);
                }}
            >
                <img
                    alt='remove'
                    src={circleCrossFilter}
                />
            </button>

        </div>
    );
}
