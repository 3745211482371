import { ThunkDispatch } from 'redux-thunk';
import { User } from 'oidc-client';

import { apiService, oidcService } from 'shared/services';
import { LOCAL_STORAGE_TOKEN_KEY } from 'shared/constants/common.consts';
import { CONFIG } from 'shared/constants/config.consts';
import { APP_PATH } from 'features/Routes/Routes.helpers';
import {
  GET_CURRENT_USER,
  GetCurrentUserAction$,
  SET_ACCESS_TOKEN,
  SetAccessTokenAction,
  SET_CURRENT_USER_ADMIN_FILTERS,
  SET_CURRENT_USER_FILTERS,
  ProceedWithoutUserAction,
  PROCEED_WITHOUT_USER,
  LoadUserAction,
  SET_SOURCE_SUBJECT_ID
} from './auth.types';
import { RootState } from '../root.reducer';
import { getCookie, clearCookie } from '../../shared/utils';
import { MSAL_TOKEN_KEY } from '../../shared/constants/common.consts';

export const getCurrentUser = (): GetCurrentUserAction$ => ({
  type: GET_CURRENT_USER,
  payload: apiService.getCurrentUser()
});

export const setAccessToken = (token: string) => (
  dispatch: ThunkDispatch<
    RootState,
    null,
    SetAccessTokenAction | GetCurrentUserAction$
  >
): void => {
  apiService.setToken(token);

  dispatch({
    type: SET_ACCESS_TOKEN,
    payload: token
  });
};

const proceedWithoutUser = (): ProceedWithoutUserAction => ({
  type: PROCEED_WITHOUT_USER
});

export const signOut = (): void => {
  localStorage.clear();
  sessionStorage.clear();
  oidcService.signOut();
};


export const retrieveAccessToken = () => async (
  dispatch: ThunkDispatch<
    RootState,
    null,
    ProceedWithoutUserAction | SetAccessTokenAction
  >
) => {
  const token = getCookie(LOCAL_STORAGE_TOKEN_KEY);

  if (token === '') {
    dispatch(proceedWithoutUser());
  } else {
    // if token is expired, redirect to login. Otherwise proceed with the currentUser.
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    const tokenData = JSON.parse(window.atob(base64));

    if (+tokenData.exp >= Date.now()) {
      signOut();
    } else {
      setAccessToken(token);
      dispatch(getCurrentUser());
    }
  }
};

export const signInUser = () => (
  dispatch: ThunkDispatch<
    RootState,
    null,
    SetAccessTokenAction | GetCurrentUserAction$
  >
): void => {
  oidcService.signInRedirectCallback();

  oidcService.events.addUserLoaded((user: User): void => {
    if (CONFIG.ENABLE_AUTO_LOGOUT) {
      apiService.getAuthToken(user.access_token).then(res => {
        setAccessToken(res.accessToken);
        dispatch(getCurrentUser());
        oidcService.getOidcUser(user);
        return res;
      });
    } else {
      setAccessToken(user.access_token);
      dispatch(getCurrentUser());
      oidcService.getOidcUser(user);
    }
  });
};

export const signInRedirect = (): void => {
  oidcService.signInRedirect();
};
export const signInRedirectTermsofUse = (pathname: any): void => {
  oidcService.signInRedirectTermsofUse(pathname);
};

export const signOutRedirect = (): void => {
  oidcService.signOutRedirectCallback();
};

export const processUserToken = (pathname: string) => (
  dispatch: ThunkDispatch<RootState, null, LoadUserAction>
): void => {
  // returns mock user when "JwtBearerAuth.MockUser": false
  if (CONFIG.MOCK_USER) {
    dispatch(getCurrentUser());
    return;
  }

  const oidcCallbacks: Record<string, AP.AnyCallback> = {
    [APP_PATH.AUTH_CALLBACK]: () => dispatch(signInUser()),
    [APP_PATH.LOGOUT_CALLBACK]: () => signOutRedirect(),
    default: () => dispatch(retrieveAccessToken())
  };

  const action = oidcCallbacks[pathname] || oidcCallbacks.default;
  action();
};

export const setCurrentUserAdminFilters = (payload: DTO.UserFilters) => ({
  type: SET_CURRENT_USER_ADMIN_FILTERS,
  payload
});

export const setCurrentUserFilters = (payload: DTO.SubmissionFilters) => ({
  type: SET_CURRENT_USER_FILTERS,
  payload
});

export const setSourceSubjectId = (payload: string) => ({
  type: SET_SOURCE_SUBJECT_ID,
  payload
});

